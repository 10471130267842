(function bindHoverToMenu () {
  const menuList = document.getElementById('menu-list');
  const menuItems = menuList.getElementsByClassName('menu_link');

  for (let i = 0; i < menuItems.length; i += 1) {
    menuItems[i].addEventListener('mouseover', () => {
      menuList.dataset.pointing = i;
    });
    menuItems[i].addEventListener('focus', () => {
      menuList.dataset.pointing = i;
    });
  }
})();

(function handleDialogs () {
  function setTabindex (elements, tabindex) {
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].setAttribute('tabindex', tabindex);
    }
  }

  function toggleTabindex (handlers, ids, hash) {
    let match = null;
    // For each handler id
    ids.forEach((id, index) => {
      // Check if it matches hash (the element is visible)
      if (`#${id}` === hash) {
        // Save match and quit
        match = index;
        return;
      }
    });

    const allFocusableElements = document.querySelectorAll('a');    

    if (match === null) {
      // Enable all elements
      setTabindex(allFocusableElements, '0');

      // Disable all elements inside all handlers
      handlers.forEach((handler) => {
        const handlerFocusableElements = handler.querySelectorAll('a');
        setTabindex(handlerFocusableElements, '-1');
      });
    } else {
      // Disable all elements
      setTabindex(allFocusableElements, '-1');
      
      // Enable all elements inside matched handler
      const handlerFocusableElements = handlers[match].querySelectorAll('a');
      setTabindex(handlerFocusableElements, '0');
    }
  }

  const dialogs = Array.from(document.querySelectorAll('[role="dialog"]'));
  const dialogsIds = dialogs.map((dialog) => dialog.id);

  toggleTabindex(dialogs, dialogsIds, location.hash);

  window.addEventListener('hashchange', () => {
    toggleTabindex(dialogs, dialogsIds, location.hash);
  });
})();

// (function handleAriaExpanded () {
//   setAriaExpanded (elements, ariaexpanded) {
//     for (let i = 0; i < elements.length; i += 1) {
//       elements[i].setAttribute('aria-expanded', ariaexpanded);
//     }
//   }

//   checkIfModal
// })();